import { useState, useRef, useEffect, } from 'react';
const useDraggable = (index) => {
    const [isMove, setIsMove] = useState(false);
    const { current: { mouse, element }, } = useRef({
        mouse: {},
        element: {},
    });
    const ref = useRef(null);
    useEffect(() => {
        if (ref?.current) {
            ref.current.style.left = `calc(25vw + ${index * 9}px)`;
            ref.current.style.top = `calc(10vh + ${index * 9}px)`;
        }
    }, []);
    const handleMouseMove = (e) => {
        const mouseTop = mouse.top ?? 0;
        const mouseLeft = mouse.left ?? 0;
        const elementTop = element.top ?? 0;
        const elementLeft = element.left ?? 0;
        const top = elementTop + e.clientY - mouseTop;
        const left = elementLeft + e.clientX - mouseLeft;
        // Убрать возможность перенемсти окно вверх
        // const canMoveTop = top > 0;
        // const canMoveLeft = left > 0;
        if (ref.current != null) {
            ref.current.style.left = `${left}px`;
            ref.current.style.top = `${top}px`;
        }
    };
    const onMouseUp = () => {
        setIsMove(false);
        document.removeEventListener('mouseup', onMouseUp);
        document.removeEventListener('mousemove', handleMouseMove);
    };
    const handleMouseDown = (e) => {
        mouse.left = e.clientX;
        mouse.top = e.clientY;
        element.left = ref.current?.offsetLeft;
        element.top = ref.current?.offsetTop;
        setIsMove(true);
        document.addEventListener('mouseup', onMouseUp);
        document.addEventListener('mousemove', handleMouseMove);
    };
    return {
        handleMouseDown,
        isMove,
        ref,
    };
};
export default useDraggable;
