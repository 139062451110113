// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DBI7htXC8xPYsxiCvtFD{
  display: flex;
  align-items: center;
  gap: 10px;
}
  .aBQhOKP0CPnfanrAESMw{
    flex-shrink: 0;
  }
  .amm66gQREyNrcyd2sO4V{
    line-height:17px
  }
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/TextWithIcon/textWithIcon.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AAOX;EANE;IACE,cAAc;EAChB;EACA;IACE;EACF","sourcesContent":[".textWithIcon{\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  &__icon{\n    flex-shrink: 0;\n  }\n  &__text{\n    line-height:17px\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textWithIcon": `DBI7htXC8xPYsxiCvtFD`,
	"textWithIcon__icon": `aBQhOKP0CPnfanrAESMw`,
	"textWithIcon__text": `amm66gQREyNrcyd2sO4V`
};
export default ___CSS_LOADER_EXPORT___;
