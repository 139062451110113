// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GRQdtisEbg2NjT4fcVSl{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/Clock/clock.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".clock{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clock": `GRQdtisEbg2NjT4fcVSl`
};
export default ___CSS_LOADER_EXPORT___;
