// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EWrtnN3OQf9VHSPbpGge{
  background-color: var(--bg);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.24);
  height: 100px;
  display: flex;
  gap:10px;
}
  .EWrtnN3OQf9VHSPbpGge > :first-child { margin-left: 10px; }
  .EWrtnN3OQf9VHSPbpGge > :last-child { margin-right: 10px; }
  .h9lmvLbkdl17dfWl6tOL{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    width: 100%;
    position: absolute;
    bottom:0px;
    z-index: 1000;
  }
  .Vy0071xMTyhADYit30VR{
    animation: vgZF27hH36x5jyRxjb3v 0.3s;
    overflow: hidden;
  }

@keyframes vgZF27hH36x5jyRxjb3v {
  0% {
    width: 0;
  }
  100% {
    width: 66px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/entities/MenuItems/ui/menuItems.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,gDAAgD;EAChD,aAAa;EACb,aAAa;EACb,QAAQ;AAiBV;EAhBE,uCAAmB,iBAAiB,EAAE;EACtC,sCAAkB,kBAAkB,EAAE;EACtC;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,aAAa;EACf;EACA;IACE,oCAAsB;IACtB,gBAAgB;EAClB;;AAGF;EACE;IACE,QAAQ;EACV;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".menuItems{\n  background-color: var(--bg);\n  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.24);\n  height: 100px;\n  display: flex;\n  gap:10px;\n  & > :first-child { margin-left: 10px; }\n  & > :last-child { margin-right: 10px; }\n  &__wrapper{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    gap: 10px;\n    width: 100%;\n    position: absolute;\n    bottom:0px;\n    z-index: 1000;\n  }\n  &__item{\n    animation: moveUp 0.3s;\n    overflow: hidden;\n  }\n}\n\n@keyframes moveUp {\n  0% {\n    width: 0;\n  }\n  100% {\n    width: 66px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItems": `EWrtnN3OQf9VHSPbpGge`,
	"menuItems__wrapper": `h9lmvLbkdl17dfWl6tOL`,
	"menuItems__item": `Vy0071xMTyhADYit30VR`,
	"moveUp": `vgZF27hH36x5jyRxjb3v`
};
export default ___CSS_LOADER_EXPORT___;
