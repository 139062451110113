import { jsx as _jsx } from "react/jsx-runtime";
import { WindowStore } from '@entities';
import { Icon } from '@ui';
import { observer } from 'mobx-react-lite';
import s from './showWindowsButton.module.scss';
const ShowWindowButton = observer(({ id, name, icon, className }) => {
    const { showWindow, moveWindowToTop } = WindowStore;
    const handleClick = () => {
        showWindow(id);
        moveWindowToTop(id);
    };
    return (_jsx("button", { type: 'button', "aria-label": name, className: `${s.showWindowsButton} ${className}`, onClick: handleClick, children: _jsx(Icon, { name: icon, id: `${id}` }) }));
});
export default ShowWindowButton;
