// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zcP7bkxXqN8woGGYlkgj{
  display: flex;
  flex-direction: row;
  gap: 3px;
  padding: 2px;
  border: 2px solid black;
}
  .aY5cLz5o8Zfr3GAwwOhj{
    border: 2px solid black;
  }
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/Loadbar/loadbar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,YAAY;EACZ,uBAAuB;AAIzB;EAHE;IACE,uBAAuB;EACzB","sourcesContent":[".loadbar{\n  display: flex;\n  flex-direction: row;\n  gap: 3px;\n  padding: 2px;\n  border: 2px solid black;\n  &__element{\n    border: 2px solid black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadbar": `zcP7bkxXqN8woGGYlkgj`,
	"loadbar__element": `aY5cLz5o8Zfr3GAwwOhj`
};
export default ___CSS_LOADER_EXPORT___;
