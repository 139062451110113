// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e40PVO7Ou1h9PuaxV8l_{
  position: relative;
  height: 100%;
}
  .zZtltU2Prp9M0prlBAeW{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .p6ioxdmELwdlfKC0Vxxz{
    position: absolute;
    right: 20px;
    top:10px;
    background-color: var(--bg);
  }
  .vKmXJXT8imV8n41il7Ex{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .ALk4s6EhdU6vONnr9rqd{
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow: auto;
    box-sizing: border-box;
    border: none;
  }
  .WHNE77ORZJJB5nNV_MOg{
    padding: 20px;
  }
  .zHYLNfK1LpTsMMEYpItQ{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }
  .Kvr0LcUSUadf4w884uls{
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 20px
  }
  .lmvVDhFARUoReesNFcB3{
    margin-top: 15px;
  }
  .aMidsCQJZE3BCtAAemBt{
    display: flex;
    flex-direction: column;
    gap:10px
  }
  .c7r24PkRUWFJ3an5jLmT{
    padding-left: 10px;
  }
`, "",{"version":3,"sources":["webpack://./src/entities/Windows/ui/windowContent/Project/project.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AAkDd;EAjDE;IACE,WAAW;IACX,YAAY;IACZ,gBAAgB;EAClB;EACA;IACE,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;EAC7B;EACA;IACE,WAAW;IACX,YAAY;IACZ,gBAAgB;EAClB;EACA;IACE,WAAW;IACX,YAAY;IACZ,cAAc;IACd,cAAc;IACd,sBAAsB;IACtB,YAAY;EACd;EACA;IACE,aAAa;EACf;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;EACA;IACE,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB;EACF;EACA;IACE,gBAAgB;EAClB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB;EACF;EACA;IACE,kBAAkB;EACpB","sourcesContent":[".project{\n  position: relative;\n  height: 100%;\n  &__iframe{\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n  }\n  &__switcher{\n    position: absolute;\n    right: 20px;\n    top:10px;\n    background-color: var(--bg);\n  }\n  &__frameContainer{\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n  }\n  &__frame{\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n    overflow: auto;\n    box-sizing: border-box;\n    border: none;\n  }\n  &__about{\n    padding: 20px;\n  }\n  &__description{\n    display: flex;\n    flex-direction: column;\n    padding-left: 10px;\n  }\n  &__heading1{\n    margin-top: 10px;\n    display: flex;\n    align-items: center;\n    gap: 20px\n  }\n  &__heading2{\n    margin-top: 15px;\n  }\n  &__list{\n    display: flex;\n    flex-direction: column;\n    gap:10px\n  }\n  &__listElement{\n    padding-left: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"project": `e40PVO7Ou1h9PuaxV8l_`,
	"project__iframe": `zZtltU2Prp9M0prlBAeW`,
	"project__switcher": `p6ioxdmELwdlfKC0Vxxz`,
	"project__frameContainer": `vKmXJXT8imV8n41il7Ex`,
	"project__frame": `ALk4s6EhdU6vONnr9rqd`,
	"project__about": `WHNE77ORZJJB5nNV_MOg`,
	"project__description": `zHYLNfK1LpTsMMEYpItQ`,
	"project__heading1": `Kvr0LcUSUadf4w884uls`,
	"project__heading2": `lmvVDhFARUoReesNFcB3`,
	"project__list": `aMidsCQJZE3BCtAAemBt`,
	"project__listElement": `c7r24PkRUWFJ3an5jLmT`
};
export default ___CSS_LOADER_EXPORT___;
