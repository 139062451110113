// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T4jz0ArHubPH_OI7vLjZ{
    line-height: 30px;
}
    .KcYUzgfx4FmGF6RLHyxZ{
      font-weight: bold;
    }
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/Text/text.module.scss"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AAIrB;IAHI;MACE,iBAAiB;IACnB","sourcesContent":[".text{\n    line-height: 30px;\n    &_bold{\n      font-weight: bold;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `T4jz0ArHubPH_OI7vLjZ`,
	"text_bold": `KcYUzgfx4FmGF6RLHyxZ`
};
export default ___CSS_LOADER_EXPORT___;
