import { jsx as _jsx } from "react/jsx-runtime";
import { WindowStore } from '@entities';
import { PanelButton } from '@ui';
import { observer } from 'mobx-react-lite';
const CollapseWindowButton = observer(({ id }) => {
    const { collapseWindow } = WindowStore;
    const handleClick = () => {
        collapseWindow(id);
    };
    return _jsx(PanelButton, { type: 'collapse', onClick: handleClick, name: `${id}` });
});
export default CollapseWindowButton;
