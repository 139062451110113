import { createContext, useContext } from 'react';
const ThemeContext = createContext({
    theme: {
        name: 'old',
        fontFamily: '',
        color: '',
        colorInFolder: '',
        fontSizeNormal: 0,
        fontSizeLarge: 0,
        fontSizeSmall: 0,
        h1: 0,
        h2: 0,
        h3: 0,
        h4: 0,
        backgroundColor: '',
        backOfElementsColor: '',
        background: '',
        backOfHeader: '',
        backOfWindowHeader: '',
        windowBorder: '',
        windowBorderRadius: 0,
        menuBorderRadius: 0,
    },
    changeTheme: () => { },
});
export const useThemeContext = () => useContext(ThemeContext);
export default ThemeContext;
