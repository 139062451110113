// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("lib/EightBits.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("lib/basis33.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("lib/SF-Pro-Display-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: EightBits;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
}
@font-face {
  font-family: EightBitsRu;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
@font-face {
  font-family: SF;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
`, "",{"version":3,"sources":["webpack://./src/App/styles/fonts.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB;AACJ;AACA;EACE,wBAAwB;EACxB,4CAA2B;AAC7B;AACA;EACE,eAAe;EACf,4CAA0C;AAC5C","sourcesContent":["@font-face {\n    font-family: EightBits;\n    src: url('lib/EightBits.ttf')\n}\n@font-face {\n  font-family: EightBitsRu;\n  src: url('lib/basis33.ttf');\n}\n@font-face {\n  font-family: SF;\n  src: url('lib/SF-Pro-Display-Regular.otf');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
