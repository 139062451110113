import { jsx as _jsx } from "react/jsx-runtime";
import s from './text.module.scss';
import { useThemeContext } from '../ThemeContext';
const Text = ({ children, size = 'Normal', className = '', bold = false, inFolder = false, }) => {
    const { theme } = useThemeContext();
    return (_jsx("span", { className: `${s.text} ${bold && s.text_bold} ${className}`, style: {
            fontSize: theme[`fontSize${size}`],
            fontFamily: theme.fontFamily,
            color: inFolder ? theme.colorInFolder : theme.color,
        }, children: children }));
};
export default Text;
