// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!./fonts.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/static/arrow.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/static/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
    --eb: 'EightBits';
    --ebr: 'EightBitsRu';
    --sf: 'SF';

    --tnn: 16px;
    --tsn: 12px;
    --tln: 20px;

    --tno: 20px;
    --tso: 16px;
    --tlo: 24px;


    --h1o: 30px;
    --h2o: 26px;
    --h3o: 24px;
    --h4o: 20px;

    --h1n: 26px;
    --h2n: 22px;
    --h3n: 20px;
    --h4n: 16px;

    --bg: #E4DCCF;
    --bgd: #F9F5EB;
    --yel: #F2E03C;
    --red: #EA5455;
    --blue: #D9D9D9;
    --grey: #BCBCBC;

    --bc:url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    --bgi:url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
body{
  margin:0;
  cursor: var(--bc), default;
  overflow: hidden;
}
button{
  cursor: var(--bc), default;
}
`, "",{"version":3,"sources":["webpack://./src/App/styles/style.css"],"names":[],"mappings":"AACA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,UAAU;;IAEV,WAAW;IACX,WAAW;IACX,WAAW;;IAEX,WAAW;IACX,WAAW;IACX,WAAW;;;IAGX,WAAW;IACX,WAAW;IACX,WAAW;IACX,WAAW;;IAEX,WAAW;IACX,WAAW;IACX,WAAW;IACX,WAAW;;IAEX,aAAa;IACb,cAAc;IACd,cAAc;IACd,cAAc;IACd,eAAe;IACf,eAAe;;IAEf,4CAA6B;IAC7B,6CAAmC;AACvC;AACA;EACE,QAAQ;EACR,0BAA0B;EAC1B,gBAAgB;AAClB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":["@import 'fonts';\n:root{\n    --eb: 'EightBits';\n    --ebr: 'EightBitsRu';\n    --sf: 'SF';\n\n    --tnn: 16px;\n    --tsn: 12px;\n    --tln: 20px;\n\n    --tno: 20px;\n    --tso: 16px;\n    --tlo: 24px;\n\n\n    --h1o: 30px;\n    --h2o: 26px;\n    --h3o: 24px;\n    --h4o: 20px;\n\n    --h1n: 26px;\n    --h2n: 22px;\n    --h3n: 20px;\n    --h4n: 16px;\n\n    --bg: #E4DCCF;\n    --bgd: #F9F5EB;\n    --yel: #F2E03C;\n    --red: #EA5455;\n    --blue: #D9D9D9;\n    --grey: #BCBCBC;\n\n    --bc:url('/static/arrow.png');\n    --bgi:url('/static/background.png');\n}\nbody{\n  margin:0;\n  cursor: var(--bc), default;\n  overflow: hidden;\n}\nbutton{\n  cursor: var(--bc), default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
