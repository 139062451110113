import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import uuid from 'react-uuid';
import s from './switcher.module.scss';
import Text from '../Text';
const Switcher = ({ name, id, values, setCurrent, current, }) => {
    const handleSwitchClick = () => {
        const currentIndex = values.findIndex(({ label }) => label === current);
        if (currentIndex === values.length - 1) {
            setCurrent(values[0].label);
            return;
        }
        setCurrent(values[currentIndex + 1].label);
    };
    return (_jsxs("div", { className: s.switcher, children: [_jsx("button", { "aria-label": name, type: 'button', onClick: handleSwitchClick, className: s.switcher__button, name: name, id: id }), _jsx("label", { className: s.switcher__label, htmlFor: name, children: values.map(({ label, name: ruName }) => (_jsx(Text, { className: `${s.switcher__item} ${label === current ? s.switcher__item_active : null}`, children: ruName }, uuid()))) })] }));
};
export default Switcher;
