// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GOHTWyMy9y90BEB0NJmZ{
  height: 100vh;
  width: 100vw;
  position: relative;
}
  .WDsErf06UToYN8nJOXBO{
    animation-name: HADyz2cOWjD0yzh4C6h3;
  }
  ._sBtlmJTdxi0mcrtLcr_{
    animation-name: izg5WrB1azEZPCMD3AZ5;
  }
  .OsVMs3GWelQhNqQhqUsH{
    animation-name: _FrJpn51kEf13ReNl1oA;
  }
  .sBQZeUt4qS_xNAlERI41{
    position:absolute;
    z-index:0;
    top:0;
    left:0;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function:linear;
  }
  .oNoHEPOL4mRLb95q0Kxp{
    position:absolute;
    z-index:0;
    right:0;
    bottom:0;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function:linear;
  }
  .b9S767PdBa5faNXelBQw{
    position:absolute;
    z-index:0;
    right:40%;
    bottom:40%;
  }

@keyframes HADyz2cOWjD0yzh4C6h3  {
  from {
    width: 40vw;
  }
  to {
    width: 41vw;
  }
}

@keyframes _FrJpn51kEf13ReNl1oA  {
  from {
    width: 20vw;
  }
  to {
    width: 19vw;
  }
}

@keyframes izg5WrB1azEZPCMD3AZ5  {
  from {
    width: 30vw;
  }
  to {
    width: 31vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/Background/background.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;AAqCpB;EApCE;IACE,oCAA+B;EACjC;EACA;IACE,oCAAgC;EAClC;EACA;IACE,oCAA+B;EACjC;EACA;IACE,iBAAiB;IACjB,SAAS;IACT,KAAK;IACL,MAAM;IACN,sBAAsB;IACtB,mCAAmC;IACnC,8BAA8B;IAC9B,gCAAgC;EAClC;EAEA;IACE,iBAAiB;IACjB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,sBAAsB;IACtB,mCAAmC;IACnC,8BAA8B;IAC9B,gCAAgC;EAClC;EACA;IACE,iBAAiB;IACjB,SAAS;IACT,SAAS;IACT,UAAU;EACZ;;AAGF;EACE;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".background{\n  height: 100vh;\n  width: 100vw;\n  position: relative;\n  &_largeAnimation{\n    animation-name: changeSizeLarge;\n  }\n  &_mediumAnimation{\n    animation-name: changeSizeMedium;\n  }\n  &_smallAnimation{\n    animation-name: changeSizeSmall;\n  }\n  &__topFigure{\n    position:absolute;\n    z-index:0;\n    top:0;\n    left:0;\n    animation-duration: 1s;\n    animation-iteration-count: infinite;\n    animation-direction: alternate;\n    animation-timing-function:linear;\n  }\n\n  &__bottomFigure{\n    position:absolute;\n    z-index:0;\n    right:0;\n    bottom:0;\n    animation-duration: 1s;\n    animation-iteration-count: infinite;\n    animation-direction: alternate;\n    animation-timing-function:linear;\n  }\n  &__centerFigure{\n    position:absolute;\n    z-index:0;\n    right:40%;\n    bottom:40%;\n  }\n}\n\n@keyframes changeSizeLarge  {\n  from {\n    width: 40vw;\n  }\n  to {\n    width: 41vw;\n  }\n}\n\n@keyframes changeSizeSmall  {\n  from {\n    width: 20vw;\n  }\n  to {\n    width: 19vw;\n  }\n}\n\n@keyframes changeSizeMedium  {\n  from {\n    width: 30vw;\n  }\n  to {\n    width: 31vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `GOHTWyMy9y90BEB0NJmZ`,
	"background_largeAnimation": `WDsErf06UToYN8nJOXBO`,
	"changeSizeLarge": `HADyz2cOWjD0yzh4C6h3`,
	"background_mediumAnimation": `_sBtlmJTdxi0mcrtLcr_`,
	"changeSizeMedium": `izg5WrB1azEZPCMD3AZ5`,
	"background_smallAnimation": `OsVMs3GWelQhNqQhqUsH`,
	"changeSizeSmall": `_FrJpn51kEf13ReNl1oA`,
	"background__topFigure": `sBQZeUt4qS_xNAlERI41`,
	"background__bottomFigure": `oNoHEPOL4mRLb95q0Kxp`,
	"background__centerFigure": `b9S767PdBa5faNXelBQw`
};
export default ___CSS_LOADER_EXPORT___;
