import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, } from 'react';
import { observer } from 'mobx-react-lite';
import uuid from 'react-uuid';
import s from './desktopElements.module.scss';
import { DesktopElementsStore } from '../model';
import { getDesktopMatrix, getPosition, height, width } from '../lib';
const DesktopElements = observer(({ ElementButton }) => {
    const { elements, setElemPosition, getElemPosition, fetchStatus } = DesktopElementsStore;
    const { current: desktopElementsMatrix } = useRef(getDesktopMatrix());
    const { innerHeight } = window;
    elements.forEach(({ position: { x, y } }) => (desktopElementsMatrix[x][y] = 1));
    const handleDrop = (e) => {
        e.preventDefault();
        const id = Number(e.dataTransfer.getData('id'));
        const dropX = Math.floor(e.clientX / width);
        const dropY = Math.floor((e.clientY - innerHeight * 0.05) / height);
        if (desktopElementsMatrix[dropX][dropY] === null && id) {
            const oldCoord = getElemPosition(id);
            if (oldCoord) {
                desktopElementsMatrix[oldCoord.x][oldCoord.y] = null;
            }
            desktopElementsMatrix[dropX][dropY] = 1;
            setElemPosition(id, dropX, dropY);
        }
    };
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDragStart = (e, id) => {
        e.dataTransfer.setData('id', `${id}`);
    };
    if (fetchStatus === 'error') {
        return _jsx("div", { children: "error" });
    }
    if (fetchStatus === 'pending') {
        return _jsx("div", { children: "loading" });
    }
    return (_jsx("div", { className: s.desktop, onDragOver: handleDragOver, onDrop: handleDrop, children: elements.map(({ name, id, icon, position: { x, y }, type, elementId, folderId, }) => {
            const { biasX, biasY } = getPosition(x, y);
            return (_jsx("div", { className: s.desktop__element, style: { top: biasY, left: biasX }, draggable: true, onDragStart: (e) => {
                    handleDragStart(e, id);
                }, children: _jsx(ElementButton, { elemId: elementId ?? folderId, type: type, name: name, id: id, icon: icon }) }, uuid()));
        }) }));
});
export default DesktopElements;
