// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B9_uA3wXsfX5Y5IiKZ6g{
  height: 100vh;
  width: 100vw;
  background-color: #2E8B57;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
  .AgHdWxIr9t0sJZEvvyR8{
    width: 96%;
  }
  .lEtaj6VjoDbRRJKLUH7Z{
    width: 80vw;
    font-family:sans-serif;
    text-align: center;
    font-size: 3vh;
    color: white;
  }
`, "",{"version":3,"sources":["webpack://./src/pages/Mobile/ui/mobile.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AAWX;EAVE;IACE,UAAU;EACZ;EACA;IACE,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,cAAc;IACd,YAAY;EACd","sourcesContent":[".mobile{\n  height: 100vh;\n  width: 100vw;\n  background-color: #2E8B57;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 20px;\n  &__svg{\n    width: 96%;\n  }\n  &__text{\n    width: 80vw;\n    font-family:sans-serif;\n    text-align: center;\n    font-size: 3vh;\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobile": `B9_uA3wXsfX5Y5IiKZ6g`,
	"mobile__svg": `AgHdWxIr9t0sJZEvvyR8`,
	"mobile__text": `lEtaj6VjoDbRRJKLUH7Z`
};
export default ___CSS_LOADER_EXPORT___;
