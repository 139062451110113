import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useThemeContext } from '@ui';
import { MenuItemStore } from '../model';
import s from './menuItems.module.scss';
const MenuItems = observer(({ ShowWindowButton }) => {
    const { items } = MenuItemStore;
    const { theme: { backOfHeader, menuBorderRadius }, } = useThemeContext();
    return (_jsx("div", { className: s.menuItems__wrapper, children: _jsx("div", { className: s.menuItems, style: {
                backgroundColor: backOfHeader,
                borderRadius: menuBorderRadius,
            }, children: items.map(({ id, name, icon }) => (_jsx(ShowWindowButton, { className: s.menuItems__item, id: id, name: name, icon: icon }, id))) }) }));
});
export default MenuItems;
