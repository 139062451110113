import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading, Icon, Link, Switcher, Text, TextWithIcon } from '@ui';
import { useState } from 'react';
import s from './project.module.scss';
import { addBrToText } from '../../../utils';
const values = [
    { label: 'about', name: 'описание' },
    { label: 'preview', name: 'превью' },
];
const Project = ({ name, description, tasks, stack, projectUrl, codeUrl, projectType, icon, iframeUrl, }) => {
    const [switcher, setSwitcher] = useState('about');
    if (iframeUrl) {
        return (_jsx("div", { className: s.project__iframe, children: _jsx("iframe", { title: `${iframeUrl}`, src: `${iframeUrl}`, width: '100%', height: '100%', allow: 'autoplay' }) }));
    }
    return (_jsxs("div", { className: s.project, children: [projectUrl && (_jsx("div", { className: s.project__switcher, children: _jsx(Switcher, { values: values, current: switcher, setCurrent: setSwitcher, name: 'projectSwitcher', id: 'projectSwitcher' }) })), switcher === 'preview' && (_jsx("div", { className: s.project__frameContainer, children: _jsx("iframe", { className: s.project__frame, title: name, src: projectUrl }) })), switcher === 'about' && (_jsxs("div", { className: s.project__about, children: [_jsxs(Heading, { className: s.project__heading1, level: 1, children: [name, icon && _jsx(Icon, { name: icon, size: 40 })] }), _jsx(Heading, { className: s.project__heading2, level: 2, children: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435" }), _jsxs("div", { className: s.project__description, children: [projectType && (_jsxs("span", { children: [_jsx(Text, { inFolder: true, children: "\u0422\u0438\u043F \u043F\u0440\u043E\u0435\u043A\u0442\u0430: " }), _jsx(Text, { inFolder: true, bold: true, children: projectType })] })), description && (_jsx(Text, { inFolder: true, className: s.project__descriptionText, children: addBrToText(description) })), codeUrl && (_jsxs("span", { children: [_jsxs(Text, { inFolder: true, bold: true, children: ["\u0421\u0441\u044B\u043B\u043A\u0430", ' '] }), _jsx(Link, { href: codeUrl, children: _jsx(Text, { inFolder: true, children: codeUrl }) })] }))] }), tasks && (_jsx(Heading, { className: s.project__heading2, level: 2, children: "\u0417\u0430\u0434\u0430\u0447\u0438" })), _jsx("div", { className: s.project__list, children: tasks?.map((task) => (_jsx(TextWithIcon, { className: s.project__listElement, iconName: 'star', children: task }))) }), stack && (_jsx(Heading, { className: s.project__heading2, level: 2, children: "\u0421\u0442\u0435\u043A" })), _jsx("div", { className: s.project__list, children: stack?.map((item) => (_jsx(TextWithIcon, { className: s.project__listElement, iconName: 'star', children: item }))) })] }))] }));
};
export default Project;
