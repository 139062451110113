import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import s from './helloWindow.module.scss';
import Text from '../Text';
import { Icon } from '../Icon';
const HelloWindow = ({ children }) => {
    const [isDisplaying, setIsDisplaying] = useState(true);
    const [startAnimation, setStartAnimation] = useState(false);
    useEffect(() => {
        const mountTimer = setTimeout(() => {
            setIsDisplaying(false);
        }, 3 * 1000);
        const animationTimer = setTimeout(() => {
            setStartAnimation(true);
        }, 2.5 * 1000);
        return () => {
            clearTimeout(mountTimer);
            clearTimeout(animationTimer);
        };
    }, []);
    if (isDisplaying) {
        return (_jsxs(_Fragment, { children: [_jsx("div", { className: `${s.helloWindow} ${startAnimation && s.helloWindow_animate}`, children: _jsxs("div", { className: s.helloWindow__content, children: [_jsx(Icon, { className: s.helloWindow__topIcon, name: 'mountains', size: 60 }), _jsx(Text, { bold: true, size: 'Large', inFolder: true, className: s.helloWindow__text, children: "Welcome to Arthur's portfolio" }), _jsx(Icon, { className: s.helloWindow__bottomIcon, name: 'mountains2', size: 60 })] }) }), children] }));
    }
    return children;
};
export default HelloWindow;
