import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import ThemeContext from './ThemeContext';
const ThemeContextProvider = ({ children, themes, }) => {
    const [theme, setTheme] = useState(themes.old);
    const changeTheme = () => {
        if (theme.name === 'old') {
            setTheme(themes.new);
        }
        else {
            setTheme(themes.old);
        }
    };
    return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    _jsx(ThemeContext.Provider, { value: { theme, changeTheme }, children: children }));
};
export default ThemeContextProvider;
