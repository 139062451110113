// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GRx6nAgprQYFNc6o5JdU {
  appearance: none;
  background-color: var(--bgd);
  border-radius: 13px;
  cursor: pointer;
  height: 26px;
  outline: none;
  position: relative;
  transition: background 0.2s linear;
  width: 45px;
}

  .GRx6nAgprQYFNc6o5JdU::before {
    background-color: var(--yel);
    border-radius: 11px;
    content: '';
    display: block;
    height: 22px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: left 0.2s linear;
    width: 22px;
  }

  .GRx6nAgprQYFNc6o5JdU:checked {
    background-color: var(--grey);
  }

  .GRx6nAgprQYFNc6o5JdU:checked::before {
      left: 21px;
    }

  .GRx6nAgprQYFNc6o5JdU:disabled {
    background-color: var(--grey);
    cursor: default;
  }
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/Toggle/toggle.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,4BAA4B;EAC5B,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,kCAAkC;EAClC,WAAW;AA2Bb;;EAzBE;IACE,4BAA4B;IAC5B,mBAAmB;IACnB,WAAW;IACX,cAAc;IACd,YAAY;IACZ,SAAS;IACT,kBAAkB;IAClB,QAAQ;IACR,4BAA4B;IAC5B,WAAW;EACb;;EAEA;IACE,6BAA6B;EAK/B;;EAHE;MACE,UAAU;IACZ;;EAGF;IACE,6BAA6B;IAC7B,eAAe;EACjB","sourcesContent":[".toggle {\n  appearance: none;\n  background-color: var(--bgd);\n  border-radius: 13px;\n  cursor: pointer;\n  height: 26px;\n  outline: none;\n  position: relative;\n  transition: background 0.2s linear;\n  width: 45px;\n\n  &::before {\n    background-color: var(--yel);\n    border-radius: 11px;\n    content: '';\n    display: block;\n    height: 22px;\n    left: 2px;\n    position: absolute;\n    top: 2px;\n    transition: left 0.2s linear;\n    width: 22px;\n  }\n\n  &:checked {\n    background-color: var(--grey);\n\n    &::before {\n      left: 21px;\n    }\n  }\n\n  &:disabled {\n    background-color: var(--grey);\n    cursor: default;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": `GRx6nAgprQYFNc6o5JdU`
};
export default ___CSS_LOADER_EXPORT___;
