// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jYASN_mIfJPI6UaAsZvN{
  border: 2px solid black;
  overflow: hidden;
}
  .pR4v0EmaIjvhEdyuEUp1{
    position:absolute;
  }
  .gmXU4UsnWcANAdSuMucT{
      display: none;
    }
  .oqVqWogVVKPix79bHlZQ{
    height: calc(100% - 33px);
    overflow-y: auto;
  }
  .ftecj8bir7MvzpNEAwSn{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .VnfSl62UlWJnkpFmTzRY{
    height: 30px;
    border-bottom: 2px solid black;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 5px 0 5px;
  }
  .oWgRD__5ex32zp2zTyO6{
      cursor: grab;
      user-select: none;
    }
  .JVLOus5EISsS5nO3hFbU{
    align-self: center;
  }
  .uDazN4jkKJc8_FiosAIB{
      user-select:none;
    }

`, "",{"version":3,"sources":["webpack://./src/entities/Windows/ui/windows.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gBAAgB;AAoClB;EAnCE;IACE,iBAAiB;EAInB;EAHE;MACE,aAAa;IACf;EAEF;IACE,yBAAyB;IACzB,gBAAgB;EAClB;EACA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,oBAAoB;EAKtB;EAJE;MACE,YAAY;MACZ,iBAAiB;IACnB;EAEF;IACE,kBAAkB;EAIpB;EAHE;MACE,gBAAgB;IAClB","sourcesContent":[".windowModal{\n  border: 2px solid black;\n  overflow: hidden;\n  &__dragContainer{\n    position:absolute;\n    &_collapse{\n      display: none;\n    }\n  }\n  &__content{\n    height: calc(100% - 33px);\n    overflow-y: auto;\n  }\n  &__message{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  &__header{\n    height: 30px;\n    border-bottom: 2px solid black;\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    padding: 0 5px 0 5px;\n    &_moving{\n      cursor: grab;\n      user-select: none;\n    }\n  }\n  &__title{\n    align-self: center;\n    &_moving{\n      user-select:none;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"windowModal": `jYASN_mIfJPI6UaAsZvN`,
	"windowModal__dragContainer": `pR4v0EmaIjvhEdyuEUp1`,
	"windowModal__dragContainer_collapse": `gmXU4UsnWcANAdSuMucT`,
	"windowModal__content": `oqVqWogVVKPix79bHlZQ`,
	"windowModal__message": `ftecj8bir7MvzpNEAwSn`,
	"windowModal__header": `VnfSl62UlWJnkpFmTzRY`,
	"windowModal__header_moving": `oWgRD__5ex32zp2zTyO6`,
	"windowModal__title": `JVLOus5EISsS5nO3hFbU`,
	"windowModal__title_moving": `uDazN4jkKJc8_FiosAIB`
};
export default ___CSS_LOADER_EXPORT___;
