import { action, makeObservable, observable, runInAction } from 'mobx';
import { getAllElements } from '../api';
class DesktopElementsStore {
    fetchStatus = null;
    elements = [];
    constructor() {
        makeObservable(this, {
            fetchStatus: observable,
            elements: observable,
            setElemPosition: action,
            getElemPosition: action,
            fetchAllElements: action,
        });
        void this.fetchAllElements();
    }
    setElemPosition = (id, x, y) => {
        this.elements = this.elements.map((elem) => elem.id === id ? { ...elem, position: { x, y } } : elem);
    };
    getElemPosition = (elemId) => this.elements.find(({ id }) => id === elemId)?.position ?? null;
    fetchAllElements = async () => {
        runInAction(() => {
            this.fetchStatus = 'pending';
        });
        try {
            const newElements = await getAllElements();
            runInAction(() => {
                this.elements = newElements;
                this.fetchStatus = null;
            });
        }
        catch (e) {
            runInAction(() => {
                this.fetchStatus = 'error';
            });
        }
    };
}
export default new DesktopElementsStore();
