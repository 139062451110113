import { jsx as _jsx } from "react/jsx-runtime";
import uuid from 'react-uuid';
import s from './loadbar.module.scss';
const loaderElementsCount = 15;
const loaderElements = Array.from(Array(loaderElementsCount).keys());
const Loadbar = ({ value, width, height }) => (_jsx("div", { style: { width, height }, className: s.loadbar, children: loaderElements.map((_, index) => (_jsx("div", { className: s.loadbar__element, style: {
            width: `${width / loaderElementsCount}%`,
            backgroundColor: index / loaderElementsCount < value ? 'black' : undefined,
        } }, uuid()))) }));
export default Loadbar;
