// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tg8248B1QExivT6duVNw{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  background-color: transparent;
  border: none;
  padding:10px;
}
  .tg8248B1QExivT6duVNw:focus{
    outline: 0.5px black dashed;
  }
  .atEezrgU8pRzObeULPRB{
    text-align: center;
    word-break: break-all;
  }
`, "",{"version":3,"sources":["webpack://./src/features/OpenModalButton/ui/openModalButton.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,6BAA6B;EAC7B,YAAY;EACZ,YAAY;AAQd;EAPE;IACE,2BAA2B;EAC7B;EACA;IACE,kBAAkB;IAClB,qBAAqB;EACvB","sourcesContent":[".openModalButton{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 120px;\n  background-color: transparent;\n  border: none;\n  padding:10px;\n  &:focus{\n    outline: 0.5px black dashed;\n  }\n  &__name{\n    text-align: center;\n    word-break: break-all;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"openModalButton": `tg8248B1QExivT6duVNw`,
	"openModalButton__name": `atEezrgU8pRzObeULPRB`
};
export default ___CSS_LOADER_EXPORT___;
