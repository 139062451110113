// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kXyUccg3rcmH2qZAWJ2F {
  width: fit-content;
  font-weight: bold;
  font-family: var(--ebr);
  clip-path: inset(0 3ch 0 0);
  animation: Cb5w4tuD_qiLYca2YqQZ 1s steps(4) infinite;
}
.kXyUccg3rcmH2qZAWJ2F:before {
  content:"Loading..."
}
@keyframes Cb5w4tuD_qiLYca2YqQZ {to{clip-path: inset(0 -1ch 0 0)}}
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/Loader/loader.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,uBAAuB;EACvB,2BAA2B;EAC3B,oDAAkC;AACpC;AACA;EACE;AACF;AACA,iCAAe,GAAG,4BAA4B,CAAC","sourcesContent":[".loader {\n  width: fit-content;\n  font-weight: bold;\n  font-family: var(--ebr);\n  clip-path: inset(0 3ch 0 0);\n  animation: l4 1s steps(4) infinite;\n}\n.loader:before {\n  content:\"Loading...\"\n}\n@keyframes l4 {to{clip-path: inset(0 -1ch 0 0)}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `kXyUccg3rcmH2qZAWJ2F`,
	"l4": `Cb5w4tuD_qiLYca2YqQZ`
};
export default ___CSS_LOADER_EXPORT___;
