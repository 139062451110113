// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p2hdAxqYAX1Zai_SQC4T{
  height: 95vh;
  width: 100%;
  position: relative;
}
  .o1xQ9aIoAwHkTHG3jhpC{
    position:absolute;
  }
`, "",{"version":3,"sources":["webpack://./src/entities/DesktopElements/ui/desktopElements.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;AAIpB;EAHE;IACE,iBAAiB;EACnB","sourcesContent":[".desktop{\n  height: 95vh;\n  width: 100%;\n  position: relative;\n  &__element{\n    position:absolute;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop": `p2hdAxqYAX1Zai_SQC4T`,
	"desktop__element": `o1xQ9aIoAwHkTHG3jhpC`
};
export default ___CSS_LOADER_EXPORT___;
