import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItemStore, WindowStore } from '@entities';
import { PanelButton } from '@ui';
import { observer } from 'mobx-react-lite';
const CloseWindowButton = observer(({ id }) => {
    const { closeWindow } = WindowStore;
    const { deleteItem } = MenuItemStore;
    const handleClick = () => {
        closeWindow(id);
        deleteItem(id);
    };
    return _jsx(PanelButton, { type: 'close', onClick: handleClick, name: `${id}` });
});
export default CloseWindowButton;
