import { action, makeObservable, observable } from 'mobx';
class MenuItemStore {
    items = [];
    constructor() {
        makeObservable(this, {
            items: observable,
            addItem: action,
            deleteItem: action,
        });
    }
    addItem = (id, name, icon) => {
        if (this.items.find(({ id: itemId }) => itemId === id)) {
            return;
        }
        this.items.push({ id, name, icon });
    };
    deleteItem = (id) => {
        this.items = this.items.filter(({ id: itemId }) => itemId !== id);
    };
}
export default new MenuItemStore();
