// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._S8xRq4SW3AbEvSSgD59{
  max-width: 100vw;
  max-height: 100vh;
  position:absolute;
}
  .lLQQVcpJRn5vKZFzIaQx{
    user-select: none;
  }
  .GM4ZsEJj45wu4e9GhUfV{
    width: 10px;
    height: 100%;
    background: transparent;
    position: absolute;
    right: -3px;
    bottom: 0;
    cursor: e-resize;
  }
  .nrQSwJw4CJYaJtKCWHx5{
    width: 10px;
    height: 100%;
    background: transparent;
    position: absolute;
    left: -3px;
    bottom: 0;
    cursor: e-resize;
  }
  .oqJJOqqLym77gjik_5cX{
    width: 100%;
    height: 10px;
    background: transparent;
    position: absolute;
    top: -6px;
    left: 0;
    cursor: n-resize;
  }
  .s3KU6_hlHR816zWW0liX{
    width: 100%;
    height: 10px;
    background: transparent;
    position: absolute;
    bottom: -3px;
    left: 0;
    cursor: n-resize;
  }
  .Cvdr700oYs0QbVHVCkoi{
    user-select:none;
  }
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/ResizeContainer/resizeContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AA2CnB;EA1CE;IACE,iBAAiB;EACnB;EACA;IACE,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,gBAAgB;EAClB;EACA;IACE,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,gBAAgB;EAClB;EACA;IACE,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,gBAAgB;EAClB;EACA;IACE,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,OAAO;IACP,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB","sourcesContent":[".resizer{\n  max-width: 100vw;\n  max-height: 100vh;\n  position:absolute;\n  &_active{\n    user-select: none;\n  }\n  &__right{\n    width: 10px;\n    height: 100%;\n    background: transparent;\n    position: absolute;\n    right: -3px;\n    bottom: 0;\n    cursor: e-resize;\n  }\n  &__left{\n    width: 10px;\n    height: 100%;\n    background: transparent;\n    position: absolute;\n    left: -3px;\n    bottom: 0;\n    cursor: e-resize;\n  }\n  &__top{\n    width: 100%;\n    height: 10px;\n    background: transparent;\n    position: absolute;\n    top: -6px;\n    left: 0;\n    cursor: n-resize;\n  }\n  &__bottom{\n    width: 100%;\n    height: 10px;\n    background: transparent;\n    position: absolute;\n    bottom: -3px;\n    left: 0;\n    cursor: n-resize;\n  }\n  &_moving{\n    user-select:none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resizer": `_S8xRq4SW3AbEvSSgD59`,
	"resizer_active": `lLQQVcpJRn5vKZFzIaQx`,
	"resizer__right": `GM4ZsEJj45wu4e9GhUfV`,
	"resizer__left": `nrQSwJw4CJYaJtKCWHx5`,
	"resizer__top": `oqJJOqqLym77gjik_5cX`,
	"resizer__bottom": `s3KU6_hlHR816zWW0liX`,
	"resizer_moving": `Cvdr700oYs0QbVHVCkoi`
};
export default ___CSS_LOADER_EXPORT___;
