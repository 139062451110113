import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDraggable } from '@lib';
import { Loader, ResizeContainer, Text, useThemeContext } from '@ui';
import s from './windows.module.scss';
import windowContent from './windowContent';
// TODO: Разбить на windowModal и draggbleContainer
const WindowModal = ({ id, name, CloseButton, CollapseButton, order, status, contentType, content, fetchStatus, ElementButton, moveOnTop, }) => {
    const { theme: { backOfElementsColor, backOfWindowHeader, windowBorder, windowBorderRadius, }, } = useThemeContext();
    const { handleMouseDown, ref, isMove } = useDraggable(order);
    const ContentComponent = windowContent[contentType];
    const handleMoveonTop = () => {
        moveOnTop(id);
    };
    // TODO: Подумать о перемещении папок в другие папки
    return (_jsx("div", { ref: ref, className: `${s.windowModal__dragContainer} ${s[`windowModal__dragContainer_${status}`]}`, style: { zIndex: order }, children: _jsxs(ResizeContainer, { className: s.windowModal, style: {
                backgroundColor: backOfElementsColor,
                borderRadius: windowBorderRadius,
                border: windowBorder,
            }, customOnMouseDown: handleMoveonTop, children: [_jsxs("div", { onMouseDown: handleMouseDown, style: {
                        backgroundColor: backOfWindowHeader,
                        borderTopLeftRadius: windowBorderRadius,
                        borderTopRightRadius: windowBorderRadius,
                        borderBottom: windowBorder,
                    }, className: `${s.windowModal__header} ${isMove ? s.windowModal__header_moving : ''}`, children: [_jsx(CloseButton, { id: id }), _jsx(CollapseButton, { id: id }), _jsx(Text, { className: `${s.windowModal__title} ${isMove ? s.windowModal__title_moving : ''}`, inFolder: true, children: name })] }), _jsxs("div", { className: s.windowModal__content, children: [fetchStatus === 'pending' && (_jsx("div", { className: s.windowModal__message, children: _jsx(Loader, { size: 30 }) })), fetchStatus === 'error' && (_jsx("div", { className: s.windowModal__message, children: _jsx(Text, { size: 'Large', bold: true, children: "Error" }) })), fetchStatus === null && (_jsx(ContentComponent, { ElementButton: ElementButton, ...content }))] })] }) }));
};
export default memo(WindowModal);
