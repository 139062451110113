import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import Text from '../Text';
import s from './clock.module.scss';
const intlTime = new Intl.DateTimeFormat('ru', {
    hour: 'numeric',
    minute: 'numeric',
});
// const intlDate = new Intl.DateTimeFormat('ru', {
//   year: 'numeric',
//   month: 'numeric',
//   day: 'numeric',
// });
const Clock = () => {
    const [time, setTime] = useState(Date.now());
    setTimeout(() => {
        setTime(Date.now());
    }, 10000);
    return (_jsx("div", { className: s.clock, children: _jsxs(Text, { bold: true, className: s.clock__text, children: [intlTime.format(time), ' '] }) }));
};
export default Clock;
