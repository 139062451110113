import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Icon, Text } from '@ui';
import { MenuItemStore, WindowStore } from '@entities';
import { observer } from 'mobx-react-lite';
import s from './openModalButton.module.scss';
const OpenModalButton = observer(({ id, icon, name, type, elemId, inFolder = false }) => {
    const [active, setActive] = useState(false);
    const { addWindow } = WindowStore;
    const { addItem } = MenuItemStore;
    const handleDoubleClick = () => {
        addWindow(name, id, type, elemId);
        addItem(id, name, icon);
    };
    const shortName = !active && name.length > 11 ? `${name.slice(0, 8)}...` : name;
    return (_jsxs("button", { name: `${id}`, className: s.openModalButton, tabIndex: 0, onDoubleClick: handleDoubleClick, onFocus: () => {
            setActive(true);
        }, onBlur: () => {
            setActive(false);
        }, children: [_jsx(Icon, { size: 66, name: icon }), _jsx(Text, { inFolder: inFolder, className: s.openModalButton__name, children: shortName })] }));
});
export default OpenModalButton;
