// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oqb8Q_Drc7NUENv9LwtK{
  color:black
}
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/Link/link.module.scss"],"names":[],"mappings":"AAAA;EACE;AACF","sourcesContent":[".link{\n  color:black\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `oqb8Q_Drc7NUENv9LwtK`
};
export default ___CSS_LOADER_EXPORT___;
