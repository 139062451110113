// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zTopP59SKsIC0Y344325{
  padding: 10px;
  display: flex;
  flex-direction: row;
}
  .JunuFMkPPQanwUVr6XEi{
    width: 100%;
    display: flex;
    justify-content: center;
  }
`, "",{"version":3,"sources":["webpack://./src/entities/Windows/ui/windowContent/Folder/folder.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;AAMrB;EALE;IACE,WAAW;IACX,aAAa;IACb,uBAAuB;EACzB","sourcesContent":[".folder{\n  padding: 10px;\n  display: flex;\n  flex-direction: row;\n  &__message{\n    width: 100%;\n    display: flex;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"folder": `zTopP59SKsIC0Y344325`,
	"folder__message": `JunuFMkPPQanwUVr6XEi`
};
export default ___CSS_LOADER_EXPORT___;
