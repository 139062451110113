// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._9SDlJc6hWO3gAytWDB5{
    padding:  4px 5px 4px 5px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    border: 2px black solid;
    width:120px;
    user-select:none;
    transition: all 0.2s;
  }
    ._9SDlJc6hWO3gAytWDB5:active{
      /* outline: 3px solid black;s*/
      background-color: var(--bgd);
    }
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/ArrayComponentsDecorator/arrayComponentDecorator.module.scss"],"names":[],"mappings":"AACE;IACE,yBAAyB;IACzB,6BAA6B;IAC7B,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,WAAW;IACX,gBAAgB;IAChB,oBAAoB;EAKtB;IAJE;MACE,8BAA4B;MAC5B,4BAA4B;IAC9B","sourcesContent":[".arrayComponentDecorator{\n  &__button{\n    padding:  4px 5px 4px 5px;\n    background-color: transparent;\n    display: flex;\n    justify-content: center;\n    border: 2px black solid;\n    width:120px;\n    user-select:none;\n    transition: all 0.2s;\n    &:active{\n      // outline: 3px solid black;s\n      background-color: var(--bgd);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrayComponentDecorator__button": `_9SDlJc6hWO3gAytWDB5`
};
export default ___CSS_LOADER_EXPORT___;
