// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gKif_u9tAbuESN42Iw1O {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: var(--bk), auto;
}
`, "",{"version":3,"sources":["webpack://./src/features/ShowWindowButton/ui/showWindowsButton.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,cAAc;EACd,6BAA6B;EAC7B,uBAAuB;AACzB","sourcesContent":[".showWindowsButton {\n  padding: 0;\n  border: none;\n  font: inherit;\n  color: inherit;\n  background-color: transparent;\n  cursor: var(--bk), auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showWindowsButton": `gKif_u9tAbuESN42Iw1O`
};
export default ___CSS_LOADER_EXPORT___;
