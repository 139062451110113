import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState,
// type RefObject,
 } from 'react';
import s from './resizeContainer.module.scss';
const ResizeContainer = ({ children, className, style, customOnClick, customOnMouseDown, }) => {
    const [resizeData, setResizeData] = useState({
        width: 700,
        height: 500,
        minWidth: 200,
        minHeight: 200,
    });
    const [resizing, setResizing] = useState(false);
    const [edge, setEdge] = useState(null);
    const ref = useRef(null);
    const { current: oldCoord } = useRef({
        top: 0,
        left: 0,
        biasX: 0,
        biasY: 0,
    });
    const hadleMouseMove = (e) => {
        if (!resizing) {
            return;
        }
        switch (edge) {
            case 'right':
                setResizeData({
                    ...resizeData,
                    width: resizeData.width + e.clientX - oldCoord.left,
                });
                break;
            case 'bottom':
                setResizeData({
                    ...resizeData,
                    height: resizeData.height + e.clientY - oldCoord.top,
                });
                break;
            case 'top':
                const height = resizeData.height - e.clientY + oldCoord.top;
                if (ref?.current != null && height >= resizeData.minHeight) {
                    ref.current.style.top = `${oldCoord.biasY + e.clientY - oldCoord.top}px`;
                }
                setResizeData({
                    ...resizeData,
                    height,
                });
                break;
            case 'left':
                const width = resizeData.width - e.clientX + oldCoord.left;
                if (ref?.current != null && width >= resizeData.minWidth) {
                    ref.current.style.left = `${oldCoord.biasX + e.clientX - oldCoord.left}px`;
                }
                setResizeData({
                    ...resizeData,
                    width,
                });
                break;
        }
    };
    const onMouseUp = () => {
        if (ref?.current != null) {
            ref.current.classList.remove(s.resizing_moving);
        }
        setResizing(false);
    };
    const hadleMouseDown = (selectedEdge, e) => {
        if (ref?.current != null) {
            ref.current.classList.add(s.resizing_moving);
            oldCoord.top = e.clientY;
            oldCoord.left = e.clientX;
            oldCoord.biasX = parseInt(`${ref.current.style.left || 0}`, 10);
            oldCoord.biasY = parseInt(`${ref.current.style.top || 0}`, 10);
        }
        setResizing(true);
        setEdge(selectedEdge);
    };
    useEffect(() => {
        document.addEventListener('mousemove', hadleMouseMove);
        document.addEventListener('mouseup', onMouseUp);
        return () => {
            document.removeEventListener('mousemove', hadleMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, [resizing]);
    return (_jsxs("div", { ref: ref, className: `${s.resizer} ${className ?? ''} ${resizing ? s.resizer_active : ''}`, style: { ...resizeData, ...(style ?? {}) }, onMouseDown: customOnMouseDown, onClick: customOnClick, children: [_jsx("span", { className: s.resizer__right, onMouseDown: (e) => {
                    hadleMouseDown('right', e);
                } }), _jsx("span", { className: s.resizer__left, onMouseDown: (e) => {
                    hadleMouseDown('left', e);
                } }), _jsx("span", { className: s.resizer__bottom, onMouseDown: (e) => {
                    hadleMouseDown('bottom', e);
                } }), _jsx("span", { className: s.resizer__top, onMouseDown: (e) => {
                    hadleMouseDown('top', e);
                } }), children] }));
};
export default ResizeContainer;
