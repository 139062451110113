import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Clock, Text, useThemeContext } from '@ui';
import { ThemeChangeToggle } from '@features';
import s from './header.module.scss';
const Header = () => {
    const { theme: { backOfHeader }, } = useThemeContext();
    console.log({ backgroundColor: backOfHeader });
    return (_jsxs("div", { style: { backgroundColor: backOfHeader }, className: s.header, children: [_jsx(Text, { bold: true, size: 'Large', children: "Arthur Boksha" }), _jsxs("div", { className: s.header__meta, children: [_jsx(ThemeChangeToggle, {}), _jsx(Clock, {}), _jsx(Text, { children: "v1.1" })] })] }));
};
export default Header;
