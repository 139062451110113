// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zIOuedN6f_ePokKHlHgY{
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  background-color: var(--bg);
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
  opacity 0.5s;

}
  .ixFE8dxzeUM8qKrRh5AT{
    opacity: 0;
  }
  .qBSC7yCKrhDsP_Hdcqdq{
    position: relative;
    height: 40vh;
    width: 40vw;
    background-color: var(--bgd);
    display: flex;
    gap:20px;
    justify-content: center;
    align-items: center;
    border: 4px double black;
  }
  .XQ81OStp6bsGbdJ3_cEg{
    position: absolute;
    z-index: 10000;
    top:30px;
    left: 40px;
  }
  .aZE97SOpxGqBzkZXbxUh{
    position: absolute;
    z-index: 10000;
    bottom:30px;
    right: 40px;
  }
  .HyVmvgeEQhlp_4k5ysx2{
    position: relative;
  }
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/HelloWindow/helloWindow.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,cAAc;EACd,2BAA2B;EAC3B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB;cACY;;AA+Bd;EA9BE;IACE,UAAU;EACZ;EACA;IACE,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,4BAA4B;IAC5B,aAAa;IACb,QAAQ;IACR,uBAAuB;IACvB,mBAAmB;IACnB,wBAAwB;EAC1B;EACA;IACE,kBAAkB;IAClB,cAAc;IACd,QAAQ;IACR,UAAU;EACZ;EACA;IACE,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,WAAW;EACb;EACA;IACE,kBAAkB;EACpB","sourcesContent":[".helloWindow{\n  position: absolute;\n  height: 100vh;\n  width: 100vw;\n  z-index: 10000;\n  background-color: var(--bg);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition:\n  opacity 0.5s;\n  &_animate{\n    opacity: 0;\n  }\n  &__text{\n    position: relative;\n    height: 40vh;\n    width: 40vw;\n    background-color: var(--bgd);\n    display: flex;\n    gap:20px;\n    justify-content: center;\n    align-items: center;\n    border: 4px double black;\n  }\n  &__topIcon{\n    position: absolute;\n    z-index: 10000;\n    top:30px;\n    left: 40px;\n  }\n  &__bottomIcon{\n    position: absolute;\n    z-index: 10000;\n    bottom:30px;\n    right: 40px;\n  }\n  &__content{\n    position: relative;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helloWindow": `zIOuedN6f_ePokKHlHgY`,
	"helloWindow_animate": `ixFE8dxzeUM8qKrRh5AT`,
	"helloWindow__text": `qBSC7yCKrhDsP_Hdcqdq`,
	"helloWindow__topIcon": `XQ81OStp6bsGbdJ3_cEg`,
	"helloWindow__bottomIcon": `aZE97SOpxGqBzkZXbxUh`,
	"helloWindow__content": `HyVmvgeEQhlp_4k5ysx2`
};
export default ___CSS_LOADER_EXPORT___;
