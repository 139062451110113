import { action, makeObservable, observable, runInAction } from 'mobx';
import { getFolderItemsByParentId, getProfileByElemId, getProjectByElemId, } from '../api';
class WindowsStore {
    windows = [];
    constructor() {
        makeObservable(this, {
            windows: observable,
            addWindow: action,
            showWindow: action,
            collapseWindow: action,
            closeWindow: action,
            moveWindowToTop: action,
            fetchWindowContent: action,
        });
    }
    addWindow = (name, id, type, elemId) => {
        if (this.windows.find(({ id: itemId }) => itemId === id)) {
            this.moveWindowToTop(id);
            return;
        }
        if (elemId === undefined) {
            this.windows.push({
                id,
                name,
                fetchStatus: 'error',
                order: this.windows.length + 1,
                status: 'open',
                type,
            });
            return;
        }
        this.windows.push({
            id,
            name,
            fetchStatus: 'pending',
            order: this.windows.length + 1,
            status: 'open',
            type,
        });
        void this.fetchWindowContent(type, elemId, id);
    };
    showWindow = (id) => {
        this.windows = this.windows.map((window) => window.id === id ? { ...window, status: 'open' } : window);
    };
    collapseWindow = (id) => {
        this.windows = this.windows.map((window) => window.id === id ? { ...window, status: 'collapse' } : window);
    };
    closeWindow = (id) => {
        this.windows = this.windows.filter(({ id: windowId }) => id !== windowId);
    };
    moveWindowToTop = (id) => {
        let isTopElemFound = false;
        this.windows = this.windows
            .sort((a, b) => a.order - b.order)
            .map((window, index, windows) => {
            if (id === window.id) {
                isTopElemFound = true;
                return {
                    ...window,
                    order: windows.length,
                };
            }
            return {
                ...window,
                order: isTopElemFound ? index : index + 1,
            };
        });
    };
    fetchWindowContent = async (type, elemId, id) => {
        try {
            if (type === 'profile') {
                const content = await getProfileByElemId(elemId);
                runInAction(() => {
                    this.windows = this.windows.map((window) => window.id === id
                        ? { ...window, content, fetchStatus: null }
                        : window);
                });
            }
            if (type === 'folder') {
                const content = await getFolderItemsByParentId(elemId);
                runInAction(() => {
                    this.windows = this.windows.map((window) => window.id === id
                        ? { ...window, content, fetchStatus: null }
                        : window);
                });
            }
            if (type === 'project') {
                const content = await getProjectByElemId(elemId);
                runInAction(() => {
                    this.windows = this.windows.map((window) => window.id === id
                        ? { ...window, content, fetchStatus: null }
                        : window);
                });
            }
        }
        catch (e) {
            runInAction(() => {
                this.windows = this.windows.map((window) => window.id === id ? { ...window, fetchStatus: 'error' } : window);
            });
        }
    };
}
export default new WindowsStore();
