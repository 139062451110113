import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense, lazy, useMemo } from 'react';
import { useThemeContext } from '../ThemeContext';
const Icon = ({ name, className, size = 66 }) => {
    const { theme: { name: themeName }, } = useThemeContext();
    const SVGElem = useMemo(() => lazy(async () => import(`./icons/${themeName === 'old' ? 'old' : 'new'}/${name}.svg`)), [name, themeName]);
    return (_jsx(Suspense, { fallback: _jsx("div", { style: { width: size, height: size }, children: "Loading..." }), children: _jsx(SVGElem, { className: className, 
            // viewBox='0 0 66 66'
            width: size, height: size }) }));
};
export default Icon;
