import { jsx as _jsx } from "react/jsx-runtime";
import { Toggle, useThemeContext } from '@ui';
import { useState } from 'react';
const ThemeChangeToggle = () => {
    const { theme: { name }, changeTheme, } = useThemeContext();
    const [value, setValue] = useState(name === 'old');
    const onChange = (val) => {
        setValue(val);
        changeTheme();
    };
    return _jsx(Toggle, { value: value, onChange: onChange });
};
export default ThemeChangeToggle;
