import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import s from './arrayComponentDecorator.module.scss';
import Text from '../Text';
const ArrayComponentsDecorator = ({ children, className = '', }) => {
    const [show, setShow] = useState(false);
    if (children.length < 4) {
        return children;
    }
    const handleButtonClick = () => {
        setShow(!show);
    };
    const displayingArray = show ? children : children?.slice(0, 4);
    return (_jsxs(_Fragment, { children: [displayingArray, _jsx("div", { className: className, children: _jsx("button", { className: s.arrayComponentDecorator__button, type: 'button', onClick: handleButtonClick, children: _jsx(Text, { inFolder: true, children: show ? 'Свернуть' : 'Развернуть' }) }) })] }));
};
export default ArrayComponentsDecorator;
