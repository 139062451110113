import { createElement } from 'react';
import s from './heading.module.scss';
import { useThemeContext } from '../ThemeContext';
const Heading = ({ level = 1, children, className = '', }) => {
    const { theme } = useThemeContext();
    return createElement(`h${level}`, {
        className: `${className} ${s.heading} ${s.heading}`,
        style: {
            fontFamily: theme.fontFamily,
            fontSize: theme[`h${level}`],
        },
    }, children);
};
export default Heading;
