// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v8DGb6LWPqe22JaLciL5{
    display: none;
  }
  .gh3wDldyM7t4eqDlLDQz{
    display: flex;
    gap: 5px;
    border: 2px black solid;
    width:fit-content;
    user-select:none;
  }
  .Ncoy3JNm5GUlkbM57tJz{
    padding:  4px 5px 4px 5px;
    width: 100px;
    display: flex;
    justify-content: center;
  }
  .EHyrNKgboy0VhfPL2FwH{
      color: var(--bg);
      background-color: black;
    }
`, "",{"version":3,"sources":["webpack://./src/shared/UIkit/Switcher/switcher.module.scss"],"names":[],"mappings":"AACE;IACE,aAAa;EACf;EACA;IACE,aAAa;IACb,QAAQ;IACR,uBAAuB;IACvB,iBAAiB;IACjB,gBAAgB;EAClB;EACA;IACE,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,uBAAuB;EAKzB;EAJE;MACE,gBAAgB;MAChB,uBAAuB;IACzB","sourcesContent":[".switcher{\n  &__button{\n    display: none;\n  }\n  &__label{\n    display: flex;\n    gap: 5px;\n    border: 2px black solid;\n    width:fit-content;\n    user-select:none;\n  }\n  &__item{\n    padding:  4px 5px 4px 5px;\n    width: 100px;\n    display: flex;\n    justify-content: center;\n    &_active{\n      color: var(--bg);\n      background-color: black;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switcher__button": `v8DGb6LWPqe22JaLciL5`,
	"switcher__label": `gh3wDldyM7t4eqDlLDQz`,
	"switcher__item": `Ncoy3JNm5GUlkbM57tJz`,
	"switcher__item_active": `EHyrNKgboy0VhfPL2FwH`
};
export default ___CSS_LOADER_EXPORT___;
