import { AxiosInstance } from '@api';
const profileEndpoint = 'profile';
const folderEndpoint = 'folders';
const projectEndpoint = 'projects';
export const getProfileByElemId = async (id) => {
    const { data } = await AxiosInstance.get(`${profileEndpoint}/${id}`);
    return data;
};
export const getProjectByElemId = async (id) => {
    const { data } = await AxiosInstance.get(`${projectEndpoint}/${id}`);
    return data;
};
export const getFolderItemsByParentId = async (id) => {
    const { data } = await AxiosInstance.get(`${folderEndpoint}/${id}`);
    return data;
};
